import React, { useState } from "react";
import { Form, Button, Radio, Tooltip, Alert } from "antd";
import { useAuth } from "../../context/AuthContext";
import { post } from "../../api/Request";
import { useTranslation } from "react-i18next";

import { ApiResWithUser, ReplyOptions } from "../../types/General";
import { QuestionCircleOutlined } from "@ant-design/icons";
import MyDivider from "../../components/helper/MyDivider";

interface AuthFormDTO {
  email: string;
  password: string;
}
const EmailOptions: React.FC = () => {
  const { user, setUser } = useAuth();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const replyOptions = [
    {
      key: 1,
      label: (
        <>
          {t("settings.replyOptions.practitioner")}{" "}
          <Tooltip title={t("settings.replyOptions.practitionerHint")}>
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      value: ReplyOptions.practitioner
    },
    {
      key: 2,
      label: (
        <>
          {t("settings.replyOptions.clinic")}{" "}
          <Tooltip title={t("settings.replyOptions.clinicHint")}>
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      value: ReplyOptions.clinic
    },
    {
      key: 3,
      label: (
        <>
          {t("settings.replyOptions.none")}{" "}
          <Tooltip title={t("settings.replyOptions.noneHint")}>
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      value: ReplyOptions.none
    }
  ];

  const onFinish = (values: AuthFormDTO) => {
    setLoading(true);
    post<ApiResWithUser>("account/upsertSettings", values)
      .then(r => {
        if (r.data.user) setUser(r.data.user);
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  return (
    <Form
      name="basic"
      // layout="vertical"
      initialValues={{
        plan_reply_to_recipient: user?.settings.plan_reply_to_recipient
      }}
      onFinish={onFinish}
      autoComplete="on"
    >
      <Alert
        className="margin-bottom"
        message={t("settings.replyToPlanEmailsHint")}
        type="info"
        closable
      />
      <MyDivider title={t("settings.replyToPlanEmails")} />
      <Form.Item name="plan_reply_to_recipient">
        <Radio.Group options={replyOptions} optionType="default" />
      </Form.Item>

      <Form.Item>
        <Button loading={loading} type="primary" htmlType="submit" block>
          {t("save")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EmailOptions;
