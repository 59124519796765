import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en_GB from "./translations/en_GB.json";
import fr_FR from "./translations/fr_FR.json";
import pl_PL from "./translations/pl_PL.json";
import es from "./translations/es_ES.json";
import fi from "./translations/fi_FI.json";
import hi from "./translations/hi_IN.json";
import it from "./translations/it_IT.json";
import pt from "./translations/pt_PT.json";
import ru from "./translations/ru.json";
import de from "./translations/de.json";
import ne from "./translations/ne.json";
import el from "./translations/el.json";
import ja from "./translations/ja.json";
import nl from "./translations/nl.json";
import bn from "./translations/bn.json";
import id from "./translations/id.json";
import zh from "./translations/zh.json";
import ur from "./translations/ur.json";
import pa from "./translations/pa.json";
import ha from "./translations/ha.json";
import mr from "./translations/mr.json";
import no from "./translations/no.json";
import sv from "./translations/sv.json";
import ta from "./translations/ta.json";
import te from "./translations/te.json";
import tr from "./translations/tr.json";
import vi from "./translations/vi.json";

// import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en_GB",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: {
      en_GB: {
        translation: en_GB
      },
      fr: {
        translation: fr_FR
      },
      pl: {
        translation: pl_PL
      },
      ru: {
        translation: ru
      },
      el: {
        translation: el
      },
      de: {
        translation: de
      },
      ne: {
        translation: ne
      },
      es: {
        translation: es
      },
      fi: {
        translation: fi
      },
      hi: {
        translation: hi
      },
      it: {
        translation: it
      },
      pt: {
        translation: pt
      },
      ja: {
        translation: ja
      },
      nl: {
        translation: nl
      },
      bn: {
        translation: bn
      },
      id: {
        translation: id
      },
      zh: {
        translation: zh
      },
      ur: {
        translation: ur
      },
      pa: {
        translation: pa
      },
      ha: {
        translation: ha
      },
      mr: {
        translation: mr
      },
      no: {
        translation: no
      },
      sv: {
        translation: sv
      },
      ta: {
        translation: ta
      },
      te: {
        translation: te
      },
      tr: {
        translation: tr
      },
      vi: {
        translation: vi
      }
    }
  });

export default i18n;
