import React, { useState } from "react";
import { Exercise, ImageType } from "../../types/Plan";
import { Row, Col, Typography, Image, List, Avatar } from "antd";
import { useTranslation } from "react-i18next";
import VideoModal from "./VideoModal";
import { useAuth } from "../../context/AuthContext";
import { Language } from "../../types/General";

interface Props {
  exercises: Exercise[];
  notes: string;
  imageType: ImageType;
  lang: Language;
}
const PlanExercisesView: React.FC<Props> = ({
  exercises,
  notes,
  lang,
  imageType
}) => {
  const { Title, Text } = Typography;
  const { t } = useTranslation();

  const [showVideo, setShowVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const { user } = useAuth();

  return (
    <>
      <div className="box3" style={{ maxWidth: 1000, margin: "0 auto" }}>
        <div
          className="grey-box multiline-text"
          hidden={!user?.settings.additional_notes_on_top}
        >
          {notes}
        </div>

        {user && exercises && (
          <>
            {exercises
              .filter(e => e.advice_sheet_pdf === "")
              .map((e, i) => (
                <Row key={i} className="plan-preview-exercise">
                  <Col span={8}>
                    <Image
                      preview={{
                        src: imageType === ImageType.photo ? e.image : e.lineart
                      }}
                      src={imageType === ImageType.photo ? e.image : e.lineart}
                    />
                  </Col>
                  <Col span={16} style={{ paddingLeft: 16 }}>
                    <Title level={4}>{e.title}</Title>
                    <Text className="multiline-text">{e.desc}</Text>
                    <Text className="plan-reps">{e.reps_formated}</Text>
                    {e.video &&
                      e.video !== "" &&
                      !user.settings.hide_videos && (
                        <Text
                          className="plan-reps pointer"
                          onClick={() => {
                            setVideoUrl(e.video!);
                            setShowVideo(true);
                          }}
                        >
                          {t("video", { lng: lang })}: {e.video}
                        </Text>
                      )}
                  </Col>
                </Row>
              ))}
            {exercises.find(e => e.advice_sheet_pdf !== "") !== undefined && (
              <div style={{ margin: "32px 0" }}>
                <h3>{t("planUpsert.adviceSheets", { lng: lang })}</h3>
                {exercises
                  .filter(e => e.advice_sheet_pdf !== "")
                  .map((e, index) => (
                    <List.Item key={e.id.toString()} actions={[]}>
                      <List.Item.Meta
                        avatar={<Avatar src={e.image} />}
                        title={e.title}
                        // description={e.desc}
                      />
                    </List.Item>
                  ))}
              </div>
            )}
          </>
        )}
        <div
          className="grey-box multiline-text"
          hidden={user?.settings.additional_notes_on_top}
        >
          {notes}
        </div>
      </div>

      <VideoModal
        url={videoUrl}
        showModal={showVideo}
        onClose={() => setShowVideo(false)}
      />
    </>
  );
};

export default PlanExercisesView;
