import React, { useEffect, useState } from "react";
import { Form, Input, Button, Alert, Row, Col, Checkbox, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../context/AuthContext";
import { QuestionCircleOutlined } from "@ant-design/icons";
import MyDivider from "../../../components/helper/MyDivider";
import { post } from "../../../api/Request";
import ConfirmButton from "../../../components/helper/ConfirmButton";
import TextContent from "../../TextContent";
const Cliniko: React.FC = () => {
  const { user, setUser } = useAuth();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loadingI, setLoadingI] = useState(false);
  const [loadingE, setLoadingE] = useState(false);
  const [loadingAk, setLoadingAk] = useState(false);

  useEffect(() => {
    // console.log(user);
    if (user) {
      form.setFieldsValue({
        software: "cliniko",
        api_key:
          user.clinic.integration_enabled === "cliniko"
            ? user.clinic.integration_api_key
            : "",
        type: "main_key"
      });
    }
  }, [user]);

  return (
    <>
      {user?.clinic.integration_enabled !== "cliniko" && (
        <TextContent id={526} />
      )}
      <Form
        name="basic"
        form={form}
        layout="vertical"
        onFinish={v => {
          // console.log(v, "upsertkey");
          setLoadingAk(true);
          post("integrations/upsertApiKey", v)
            .then(r => {
              if (r.data.user) setUser(r.data.user);
            })
            .catch(_ => {})
            .finally(() => setLoadingAk(false));
        }}
        autoComplete="on"
      >
        <Form.Item hidden name="software">
          <Input />
        </Form.Item>
        <Form.Item hidden name="type">
          <Input />
        </Form.Item>
        <Form.Item
          label={t("integrations.enterApiKey", { integration: "Cliniko" })}
          name="api_key"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button loading={loadingAk} type="primary" htmlType="submit" block>
            {t("save")}
          </Button>
        </Form.Item>
      </Form>
      {user?.clinic.integration_enabled === "cliniko" && (
        <>
          <Alert
            description={t("cliniko.note")}
            type="info"
            closable
            message="Note"
            style={{ marginBottom: 32 }}
          />
          <Row gutter={16}>
            <Col span={12}>
              <MyDivider title={t("integrations.exportTo", { i: "Cliniko" })} />
              <Form
                name="basic"
                layout="vertical"
                onFinish={v => {
                  setLoadingE(true);
                  post("cliniko/exportToCliniko", v)
                    .then(r => {})
                    .catch(_ => {})
                    .finally(() => {
                      setLoadingE(false);
                    });
                }}
                initialValues={{
                  review_same_email: true,
                  review_same_name: true
                }}
                autoComplete="on"
              >
                <Form.Item name="review_same_email" valuePropName="checked">
                  <Checkbox>
                    {t("integrations.dontExportSameEmail", {
                      integration: "Cliniko"
                    })}{" "}
                    <Tooltip
                      title={t("integrations.dontExportSameEmailHint", {
                        integration: "Cliniko"
                      })}
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Checkbox>
                </Form.Item>
                <Form.Item name="review_same_name" valuePropName="checked">
                  <Checkbox>
                    {t("integrations.dontExportSameName", {
                      integration: "Cliniko"
                    })}{" "}
                    <Tooltip
                      title={t("integrations.dontExportSameNameHint", {
                        integration: "Cliniko"
                      })}
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={loadingE}
                    type="primary"
                    htmlType="submit"
                    block
                  >
                    {t("integrations.exportRmpPatients")}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col span={12}>
              <MyDivider
                title={t("integrations.importFrom", { i: "Cliniko" })}
              />
              <Form
                name="basic"
                layout="vertical"
                onFinish={v => {
                  setLoadingI(true);

                  post("cliniko/importFromCliniko", v)
                    .then(r => {})
                    .catch(_ => {})
                    .finally(() => {
                      setLoadingI(false);
                    });
                }}
                initialValues={{
                  review_same_email: true,
                  review_same_name: true
                }}
                autoComplete="on"
              >
                <Form.Item name="review_same_email" valuePropName="checked">
                  <Checkbox>
                    {t("integrations.reviewImportEmail")}{" "}
                    <Tooltip title={t("integrations.reviewImportEmailTip")}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Checkbox>
                </Form.Item>
                <Form.Item name="review_same_name" valuePropName="checked">
                  <Checkbox>
                    {t("integrations.reviewImportName")}{" "}
                    <Tooltip title={t("integrations.reviewImportEmailTip")}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={loadingI}
                    type="primary"
                    htmlType="submit"
                    block
                  >
                    {t("integrations.importXPatients", { i: "Cliniko" })}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row style={{ marginTop: 64 }}>
            <Col style={{ textAlign: "right" }}>
              <ConfirmButton
                btnText={t("integrations.disable")}
                message={t("integrations.disableConfirm", { i: "Cliniko" })}
                btnProps={{ block: true, size: "small", danger: true }}
                onConfirm={() => {
                  post("integrations/disable")
                    .then(r => {
                      if (r.data.user) setUser(r.data.user);
                    })
                    .catch(_ => {})
                    .finally(() => {});
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Cliniko;
