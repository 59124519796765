import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Select,
  Radio,
  Tooltip,
  Row
} from "antd";
import { useAuth } from "../../context/AuthContext";
import { get, post } from "../../api/Request";
import { EmailSys, SmtpSettings } from "../../types/UserData";
import { useTranslation } from "react-i18next";

import { ApiResWithUser } from "../../types/General";
import { QuestionCircleOutlined } from "@ant-design/icons";
import MyDivider from "../../components/helper/MyDivider";
import ColHalf from "../../components/layout/ColHalf";
import TextTemplateEdit from "../../components/helper/TextTemplateEdit";

interface AuthFormDTO {
  email: string;
  password: string;
}
const EmailSettings: React.FC = () => {
  const [form] = Form.useForm();
  const { user, setUser } = useAuth();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [smtpCredentials, setSmtpCredentials] = useState<SmtpSettings>();
  const [smtpOn, setSmtpOn] = useState(false);

  const receiveOptions = [
    {
      key: "1",
      label: t("settings.email.sendToPatient"),
      value: false
    },
    {
      key: "2",
      label: t("settings.email.sendToPatientAndClinic"),

      value: true
    }
  ];
  const onFinish = (values: AuthFormDTO) => {
    setLoading(true);
    post<ApiResWithUser>("account/emailSettingsEdit", values)
      .then(r => {
        if (r.data.user) {
          setUser(r.data.user);
        }
        // if (r.data.clinics.length > 1) navigate("/choose-clinic", {replace:true});
        // else navigate("/patients", {replace:true});
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (user && smtpCredentials) {
      let formData = {
        reply_to: user.settings.reply_to,
        email_sys: user.settings.email_sys,
        use_patient_email_cc_and_bcc_fields:
          user.settings.use_patient_email_cc_and_bcc_fields,
        forward_plan_email_to_clinic:
          user.settings.forward_plan_email_to_clinic,
        forward_cc: user.settings.forward_cc,
        forward_bcc: user.settings.forward_bcc,
        host: smtpCredentials.host,
        port: smtpCredentials.port,
        email: smtpCredentials.email,
        password: smtpCredentials.password,
        ssl_tls: smtpCredentials.ssl_tls,
        require_auth: smtpCredentials.require_auth
      };

      //// console.log("setting form", formData);
      form.setFieldsValue(formData);
    }
    if (user) setSmtpOn(user.settings.email_sys === EmailSys.smtp);
  }, [user, smtpCredentials]);

  useEffect(() => {
    get("account/smtpCredentials", {})
      .then(r => setSmtpCredentials(r.data))
      .catch(_ => {})
      .finally(() => setLoading(false));
  }, [user]);

  return (
    <Form
      form={form}
      name="basic"
      layout="vertical"
      className="segmented"
      onFinish={onFinish}
      autoComplete="on"
      onFieldsChange={fc => {
        // console.log(fc, "f change");
        if (fc[0] !== undefined && fc[0]["name"]["0"] === "email_sys")
          setSmtpOn(fc[0]["value"] === EmailSys.smtp);
      }}
    >
      <MyDivider
        title={t("settings.replyToOptions")}
        tooltip={t("settings.replyToOptionsMsg")}
      />

      <Form.Item
        label={t("settings.email.defaultReplyToEmail")}
        name="reply_to"
      >
        <Input />
      </Form.Item>

      <MyDivider title={t("settings.email.whoWillReceive")} />
      <Form.Item
        name="use_patient_email_cc_and_bcc_fields"
        valuePropName="checked"
      >
        <Checkbox>{t("settings.email.useCcBcc")} </Checkbox>
      </Form.Item>
      <Form.Item name="forward_plan_email_to_clinic">
        <Radio.Group
          options={receiveOptions}
          // optionType="button"
          // buttonStyle="solid"
        />
      </Form.Item>

      <MyDivider title={t("settings.email.forwardPlansTo")} />
      <Form.Item name="forward_cc">
        <Input placeholder="CC" />
      </Form.Item>
      <Form.Item name="forward_bcc">
        <Input placeholder="BCC" />
      </Form.Item>
      <MyDivider title={t("settings.email.emailSending")} />
      <Form.Item name="email_sys">
        <Radio.Group
        // options={sendingOptions}
        // optionType="button"
        // buttonStyle="solid"
        >
          <Radio value={EmailSys.smtp}>
            {t("settings.email.sendUsingSmtp")}{" "}
            <Tooltip title={t("settings.email.sendUsingSmtpMsg")}>
              <QuestionCircleOutlined />
            </Tooltip>
          </Radio>
          <Radio value={EmailSys.rmp}>
            {t("settings.email.sendUsingRmp")}{" "}
            <Tooltip title={t("settings.email.sendUsingRmpMsg")}>
              <QuestionCircleOutlined />
            </Tooltip>
          </Radio>
        </Radio.Group>
      </Form.Item>

      {smtpOn && (
        <>
          <MyDivider title={t("settings.email.smtpSettings")} />
          <Row>
            <ColHalf>
              <Form.Item label={t("settings.email.outgoingServer")} name="host">
                <Input />
              </Form.Item>
            </ColHalf>
            <ColHalf>
              <Form.Item label={t("settings.email.port")} name="port">
                <Input />
              </Form.Item>
            </ColHalf>
          </Row>

          <Form.Item label={t("email")} name="email">
            <Input />
          </Form.Item>
          <Form.Item label={t("password")} name="password">
            <Input />
          </Form.Item>
          <Form.Item
            label={t("settings.email.encryptedConnection")}
            name="ssl_tls"
          >
            <Select
              style={{ width: "100%" }}
              // onChange={handleChange}
              options={[
                {
                  value: "",
                  label: t("settings.email.none")
                },
                {
                  value: "ssl",
                  label: "SSL"
                },
                {
                  value: "tls",
                  label: "TLS"
                }
              ]}
            />
          </Form.Item>
          <Form.Item
            label={t("settings.email.authRequired")}
            name="require_auth"
          >
            <Select
              style={{ width: "100%" }}
              // onChange={handleChange}
              options={[
                {
                  value: "1",
                  label: t("yes")
                },
                {
                  value: "0",
                  label: t("no")
                }
              ]}
            />
          </Form.Item>
        </>
      )}

      <MyDivider title={t("settings.email.outgoingEmailMessage")} />

      <Form.Item name="custom_outgoing_msg" hidden>
        <Input />
      </Form.Item>
      <TextTemplateEdit
        rows={14}
        type="custom_outgoing_msg"
        onChange={d => {
          form.setFieldsValue({
            ...form.getFieldsValue(),
            custom_outgoing_msg: d
          });
        }}
      />
      <MyDivider title={t("settings.email.trMeetingEmailTemplate")} />

      <Form.Item name="meeting_invite_msg" hidden>
        <Input />
      </Form.Item>
      <TextTemplateEdit
        rows={14}
        type="tr_invite_msg"
        onChange={d => {
          form.setFieldsValue({
            ...form.getFieldsValue(),
            meeting_invite_msg: d
          });
        }}
      />
      <Form.Item>
        <Button loading={loading} type="primary" htmlType="submit" block>
          {t("save")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EmailSettings;
