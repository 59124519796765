import React from "react";
import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { LanguageNames } from "../types/General";

const { Option } = Select;
const languageOptions = Object.entries(LanguageNames);

interface LanguageSelectProps extends SelectProps<string> {
  // You can add any additional props specific to the LanguageSelect component here
}

const LanguageSelect: React.FC<LanguageSelectProps> = ({ ...restProps }) => {
  return (
    <Select {...restProps}>
      {languageOptions.map(([langCode, langName]) => (
        <Option key={langCode} value={langCode}>
          {langName}
        </Option>
      ))}
    </Select>
  );
};

export default LanguageSelect;
