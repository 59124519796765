import { Result } from "antd";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import MyModal from "../../components/MyModal";
import PractitionerPlanChart from "./PractitionerPlanChart";
import { get } from "../../api/Request";
import LevelIcon from "../../components/helper/LevelIcon";
import { useAuth } from "../../context/AuthContext";
import PractitionerLevelDisplay from "./PractitionerLevelDisplay";

interface Props {
  practitionerId: number;
  showModal: boolean;
  onCancel: () => void;
}

const PlanChartModal: React.FC<Props> = ({
  practitionerId,
  showModal,
  onCancel
}) => {
  const { t } = useTranslation();

  return (
    <MyModal
      title={t("pract.stats.title")}
      open={showModal}
      onCancel={onCancel}
      destroyOnClose={true}
      okButtonProps={{ hidden: true }}
      cancelText={t("close")}
      width={1000}
    >
      <PractitionerLevelDisplay practitionerId={practitionerId} />
      <PractitionerPlanChart practitionerId={practitionerId} />
    </MyModal>
  );
};

export default PlanChartModal;
