import { UserObject } from "./UserData";

export enum Language {
  arAr = "ar",
  bnBn = "bn",
  deDe = "de",
  elEl = "el",
  enGB = "en-GB",
  enUS = "en-US",
  esES = "es",
  fiFI = "fi",
  frFR = "fr",
  haNg = "ha",
  hiIN = "hi",
  idId = "id",
  itIT = "it",
  jaJa = "ja",
  mrMr = "mr",
  neNe = "ne",
  nlNl = "nl",
  noNo = "no",
  paPa = "pa",
  plPl = "pl",
  ptPT = "pt",
  ruRu = "ru",
  svSv = "sv",
  taTa = "ta",
  teTe = "te",
  trTr = "tr",
  urUr = "ur",
  viVi = "vi",
  zhCn = "zh"
}

export const LanguageNames: Record<Language, string> = {
  [Language.arAr]: "Arabic",
  [Language.bnBn]: "Bengali",
  [Language.deDe]: "German",
  [Language.elEl]: "Greek",
  [Language.enGB]: "English (UK)",
  [Language.enUS]: "English (US)",
  [Language.esES]: "Spanish",
  [Language.fiFI]: "Finnish",
  [Language.frFR]: "French",
  [Language.haNg]: "Hausa",
  [Language.hiIN]: "Hindi",
  [Language.idId]: "Indonesian",
  [Language.itIT]: "Italian",
  [Language.jaJa]: "Japanese",
  [Language.mrMr]: "Marathi",
  [Language.neNe]: "Nepali",
  [Language.nlNl]: "Dutch",
  [Language.noNo]: "Norwegian",
  [Language.paPa]: "Punjabi",
  [Language.plPl]: "Polish",
  [Language.ptPT]: "Portuguese",
  [Language.ruRu]: "Russian",
  [Language.svSv]: "Swedish",
  [Language.taTa]: "Tamil",
  [Language.teTe]: "Telugu",
  [Language.trTr]: "Turkish",
  [Language.urUr]: "Urdu",
  [Language.viVi]: "Vietnamese",
  [Language.zhCn]: "Chinese"
};

export interface ApiResWithUser {
  status: string;
  message: string;
  successToast: string;
  user?: UserObject;
}
export interface ApiRes {
  status: string;
  message: string;
  successToast: string;
  user?: UserObject;
}

export enum RepsFormat {
  default = "default",
  custom = "custom"
}

export enum ReplyOptions {
  practitioner = "practitioner",
  clinic = "clinic",
  none = "none"
}
