import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Descriptions,
  Result,
  Select,
  Alert
} from "antd";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { post } from "../../api/Request";
import { Licence } from "../../types/UserData";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ApiResWithUser } from "../../types/General";
import { UserRole } from "../../types/Practitioner";
import MyDivider from "../../components/helper/MyDivider";
import ColHalf from "../../components/layout/ColHalf";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import LanguageSelect from "../../components/LanguageSelect";

const { Option } = Select;
const ManageMyClinics: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { user, setUser } = useAuth();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      if (![UserRole.admin, UserRole.owner].includes(user.practitioner.role))
        navigate("/settings");

      form.resetFields();
      form.setFieldsValue({ id: 0 });
    }
  }, [user, navigate, form]);

  const onFinish = values => {
    setLoading(true);
    post<ApiResWithUser>("account/transferPatients", values)
      .then(r => {
        if (r.data.user !== undefined) {
          if (r.data.user) setUser(r.data.user);
          // if (r.data.user.clinics.length > 1)
          navigate("/settings/edit-clinic", { replace: true });
        }
        // else navigate("/patients", {replace:true});
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  return (
    <>
      {user && (
        <>
          <MyDivider title={t("settings.manageClinics.transferPatients")} />

          <Form
            name="basic"
            form={form}
            layout="vertical"
            onFinish={values => {
              setLoading(true);
              post("account/transferPatients", values)
                .then(r => {})
                .catch(_ => {})
                .finally(() => setLoading(false));
            }}
            hidden={user.current_clinics.length === user.clinics_allowed}
            preserve={false}
          >
            <Form.Item hidden name="id" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Row gutter={16}>
              <ColHalf>
                <Form.Item
                  label={t("settings.manageClinics.transferFrom")}
                  name="from"
                  initialValue={user.clinic.id}
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={true}
                  >
                    {user &&
                      user.clinics
                        ?.filter(c => c.member_id === user.id)
                        .map(c => (
                          <Option value={c.id} key={c.id}>
                            {c.clinic_name}
                          </Option>
                        ))}
                  </Select>
                </Form.Item>
              </ColHalf>
              <ColHalf>
                <Form.Item
                  label={t("settings.manageClinics.transferTo")}
                  name="to"
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={true}
                  >
                    {user &&
                      user.clinics
                        ?.filter(c => c.member_id === user.id)
                        .map(c => (
                          <Option value={c.id} key={c.id}>
                            {c.clinic_name}
                          </Option>
                        ))}
                  </Select>
                </Form.Item>
              </ColHalf>
            </Row>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit" block>
                {t("settings.manageClinics.transferPatients")}
              </Button>
            </Form.Item>
          </Form>
          <MyDivider title={t("settings.manageClinics.delClinic")} />

          <Form
            name="basic"
            layout="vertical"
            onFinish={values => {
              setLoading(true);
              post("account/deleteClinic", values)
                .then(r => {
                  setUser(r.data.user);
                })
                .catch(_ => {})
                .finally(() => setLoading(false));
            }}
            hidden={user.current_clinics.length === user.clinics_allowed}
            preserve={false}
          >
            <Row gutter={16}>
              <ColHalf>
                <Form.Item
                  label={t("account.chooseClinic")}
                  name="clinic_id"
                  initialValue={user.clinic.id}
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={true}
                  >
                    {user &&
                      user.clinics
                        ?.filter(c => c.member_id === user.id)
                        .map(c => (
                          <Option value={c.id} key={c.id}>
                            {c.clinic_name}
                          </Option>
                        ))}
                  </Select>
                </Form.Item>
              </ColHalf>
              <ColHalf>
                <Alert
                  message={t("settings.manageClinics.delClinicHint")}
                  type="warning"
                />
              </ColHalf>
            </Row>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit" block>
                {t("settings.manageClinics.delClinic")}
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </>
  );
};

export default ManageMyClinics;
